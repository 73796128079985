/**
 * THIS ENTRY IS USED FOR FRONTEND JAVASCIPT.
 */

/**
 * Load external dependencies.
 */
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'instant.page';
// import Headroom from 'headroom.js';

/**
 * Load internal dependencies.
 */
import init from './init';

init();

/**
 * Offcanvas mobile menu.
 */
// TODO: Lazyload bootstrap (offscreen) since we don't need it on page load.
const offcanvas = document.getElementById( 'offcanvasMobile' );
const navicon = document.getElementById( 'navicon' );

if ( offcanvas && navicon ) {
	offcanvas.addEventListener( 'show.bs.offcanvas', function() {
		document.body.classList.add( 'offcanvas-open' );
		navicon.classList.add( 'is-active' );
	} );
	offcanvas.addEventListener( 'hide.bs.offcanvas', function() {
		document.body.classList.remove( 'offcanvas-open' );
		navicon.classList.remove( 'is-active' );
	} );
}
