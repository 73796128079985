/**
 * Create Multiple Swipers using {@link createSwiper}.
 *
 * @param {string|HTMLElement|HTMLCollection} selector
 * @param {SwiperOptions}                     options
 *
 * @return {Promise<Swiper[]>} Swiper instances.
 */
export const createMultipleSwipers = async ( selector, options = {} ) => {
	let elements = [];

	// Single element.
	if ( selector instanceof HTMLElement ) {
		elements = [ selector ];
	}

	// Multiple elements via selector.
	if ( typeof selector === 'string' ) {
		elements = [ ...document.querySelectorAll( selector ) ]; // Convert to array so we have a consistent type.
	}

	// Multiple elements.
	if ( selector instanceof HTMLCollection || selector instanceof NodeList ) {
		elements = [ ...selector ]; // Convert to array so we have a consistent type.
	}

	// Exit if no element exists.
	if ( elements.length === 0 ) {
		return [];
	}

	return await Promise.all( elements.map( ( element ) => createSwiper( element, options ) ) );
};

/**
 * Create Swiper with lazy loading.
 *
 * @param {string|HTMLElement} selector
 * @param {SwiperOptions}      options
 *
 * @return {Promise<Swiper|undefined>} Swiper instance.
 */
const createSwiper = async ( selector, options = {} ) => {
	const element = typeof selector === 'string' ? document.querySelector( selector ) : selector;

	// Exit if element doesn't exist.
	if ( ! element ) {
		return;
	}

	// Import dependencies.
	const { default: Swiper, Navigation, Pagination, Thumbs, Controller } = await import( /* webpackChunkName: "swiper" */ 'swiper' );

	// Create Swiper instance.
	const swiper = new Swiper( element, {
		modules: [ Navigation, Pagination, Thumbs, Controller ],
		...options,
	} );

	return swiper;
};

export default createSwiper;
