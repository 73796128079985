
/**
 * Load external dependencies.
 */
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

/**
 * Load internal dependencies.
 */
import Drilldown from './_vendor/drilldown/drilldown';
import createSwiper from './components/swiper';
// import createGLightbox from './components/glightbox';
import './components/popper.js';

/**
 * Initialize javascript functionality.
 */
const init = async () => {
	/**
	 * Blocks
	 */

	// Maps.
	const mapsBlock = document.querySelector( '.block-calculator' );
	if ( mapsBlock ) {
		import( './blocks/block-calculator.js' ).then( ( { default: InitBlock } ) => new InitBlock() );
	}

	/**
	 * Drilldown menu.
	 *
	 */
	const drilldowns = document.querySelectorAll( '.drilldown' );
	for ( const drilldown of drilldowns ) {
		new Drilldown( drilldown );
	}

	/**
	 * Toggle preload classes.
	 */
	document.body.classList.remove( 'preload' );
	document.body.classList.add( 'animation-preload' );
	setTimeout( () => document.body.classList.remove( 'animation-preload' ), 1000 );

	/**
	 * Create Swiper, (Navigation, Pagination, Thumbs, Controller) are already inserted.
	 */
	createSwiper( '.swiper-slider', {
		slidesPerView: 2.5,
		spaceBetween: 20,
		breakpoints: {
			0: {
				slidesPerView: 1.2,
				spaceBetween: 20,
			},
			800: {
				slidesPerView: 1.5,
				spaceBetween: 20,
			},
			1600: {
				slidesPerView: 2.5,

			},
		},
		navigation: {
			nextEl: '.button-next',
			prevEl: '.button-prev',
		},
	} );

	// init lazy loaded ajax filter javscript
	const ajaxFilters = document.querySelectorAll( '[data-b2-filter]' );
	if ( ajaxFilters.length > 0 ) {
		import( /* webpackChunkName: "ajax-filter" */ './components/b2-ajax-filter' ).then( ( { default: AjaxFilter } ) => {
			for ( const ajaxFilter of ajaxFilters ) {
				new AjaxFilter( ajaxFilter );
			}
		} );
	}

	// init lazy loaded maps
	const maps = document.querySelectorAll( '.block-maps' );
	for ( const map of maps ) {
		import(
			/* webpackChunkName: "maps" */ './blocks/block-maps'
		).then( ( { default: Init } ) => new Init( map ) );
	}
};

export default init;
