import { createPopper } from '@popperjs/core';

// popper
function isPopper( target ) {
	return 'popper' in target.dataset;
}

function showPopper( e ) {
	const target = e.target;
	if ( isPopper( target ) ) {
		console.log( 'showPopper' );
		e.preventDefault();
		e.stopPropagation();
		tooltipContent.innerHTML = target.dataset.popper;
		createPopper( target, tooltip, {
			placement: 'top',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [ 0, 8 ],
					},
				},
			],
		} );
		tooltip.classList.add( 'is-active' );
	}
}

function hidePopper( e ) {
	const target = e.target;
	if ( isPopper( target ) ) {
		tooltip.classList.remove( 'is-active' );
	}
}

const tooltip = document.querySelector( '#popper' );
const tooltipContent = tooltip.querySelector( '[data-popper-content]' );
document.body.addEventListener( 'mouseenter', ( e ) => showPopper( e ), true );
document.body.addEventListener( 'focus', ( e ) => showPopper( e ), true );
document.body.addEventListener( 'mouseleave', ( e ) => hidePopper( e ), true );
document.body.addEventListener( 'blur', ( e ) => hidePopper( e ), true );
document.body.addEventListener( 'click', ( e ) => showPopper( e ) );
